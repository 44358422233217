import * as Leaflet from "leaflet";
import pin from "./marker-icon.png";
import shadow from "./marker-shadow.png";

type Place = {
    lat: number;
    lon: number;
    address: {
        city: string;
        state_district: string;
        state: string;
        country: string;
    };
};

const main = async () => {
    const mapContainer = document.getElementById("Map");
    const q = mapContainer.dataset.query;

    const data: Array<Place> = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${q}`).then(
        (response) => response.json()
    );

    const place = data.length > 0 ? data[0] : null;

    if (place) {
        const map = Leaflet.map("Map").setView([place.lat, place.lon], 18);

        Leaflet.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
            maxZoom: 19,
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        }).addTo(map);

        const markerIcon = new Leaflet.Icon({
            iconAnchor: [25, 41],
            iconUrl: pin,
            shadowUrl: shadow,
        });

        const marker = Leaflet.marker([place.lat, place.lon], { icon: markerIcon }).addTo(map);

        marker.bindPopup(q, { offset: [-12, -20], maxWidth: 200 }).openPopup();
    }
};

main();
